import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const AboutTab = () => {
  const { t } = useTranslation('rightSidebar');

  return (
    <div>


      <hr className="my-5" />





      <div className="mt-5">
        <p className="text-xs font-gray-600 text-center">
          <Trans t={t} i18nKey="about.footer.credit">
            Powered by
            <a
              className="font-bold hover:underline"
              href="https://pistis.io/welcome/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Pistis.io
            </a>
          </Trans>
        </p>
        <p className="text-xs font-gray-600 text-center">{t('about.footer.thanks')}</p>
      </div>
    </div>
  );
};

export default AboutTab;
